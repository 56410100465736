<template>
  <div class="material-list">
    <div class="empty-state">
      <v-icon class="empty-state-icon">mdi-file-document-outline</v-icon>
      <div class="button-container">
        <v-btn class="upload-button" color="primary" @click="openUploadModal">Neue Dokument hochladen</v-btn>
        <v-btn class="show-pdf" color="primary" @click="viewPDF">Dokument anzeigen</v-btn>
      </div>
    </div>

    <v-dialog v-model="modalOpen" max-width="500px">
      <template v-slot:activator="{ on }">
        <div class="add-new-user" @click.prevent="openUploadModal"></div>
      </template>
      <v-card>
        <v-card-title>
          <span class="document-label">Dokument hochladen</span>
        </v-card-title>
        <v-card-text>
          <div class="form-control" style="width: 100%">
            <label for="fileInput-MaterialList" style="height: 24px"></label>
            <div class="coverage-div"></div>
            <input
              ref="documentFilesMaterialList"
              type="file"
              id="fileInput-MaterialList"
              placeholder="Dokument hochladen"
              title="Dokument hochladen"
              @change="handleFileUpload"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn class="closeButton" color="primary" @click="closeUploadModal">Abbrechen</v-btn>
          <v-btn color="primary" @click="uploadMaterialList">Upload</v-btn>
        </v-card-actions>
      </v-card>
            <v-alert type="success" dismissible elevation="20" v-show="showAlert" :color="alertColor" class="alert">
          {{ alertText }}
        </v-alert>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      pdfFile: null, 
      modalOpen: false,
      showAlert: false,
      alertText: "",
      alertColor:"",
    };
  },
  methods: {
      reloadPage() {
    this.showAlert = false; // Hide the alert
    setTimeout(() => {
      window.location.reload();
    }, 100); // Delay for a short time to allow alert to hide before reloading
  },

  async viewPDF() {
    try {
      const response = await axios.get('https://tilia.rrota.org/api/Management/GetTasksMaterial');
      const pdfURL = response.data;

      // Check if the platform is web
      if (Capacitor.getPlatform() === 'web') {
        // Open the PDF in a new window for web
        window.open(pdfURL, '_blank');
      } else {
        // Use Capacitor Filesystem to open the PDF for native platforms (e.g., iPad)
        const { Filesystem } = Capacitor.Plugins;
        
        // Define the directory path based on your app's structure
        const directoryPath = 'path/to/pdf/directory';
        
        // Use Filesystem to read the PDF file
        const fileContent = await Filesystem.readFile({
          path: `${directoryPath}/your_pdf_file.pdf`,
          directory: FilesystemDirectory.Documents,
        });

        // Convert the file content to a data URL
        const dataURL = 'data:application/pdf;base64,' + fileContent.data;

        // Open the PDF in a new window
        window.open(dataURL, '_blank');
      }
    } catch (error) {
      console.error('Error fetching or opening PDF:', error);
    }
  },
    openUploadModal() {
      this.modalOpen = true;
    },
    closeUploadModal() {
      this.modalOpen = false;
    },
    handleFileUpload(event) {
      const file = event.target.files[0]; 
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const base64Data = event.target.result.split(",")[1]; 
          this.pdfFile = {
            NameBase64: base64Data,
            name: "Material List",
            url: URL.createObjectURL(file),
          };
        };
        reader.readAsDataURL(file);
      }
    },
uploadMaterialList() {
  if (this.pdfFile) {
    const fileData = {
      FileName: "random_name.pdf",
      NameBase64: this.pdfFile.NameBase64,
      FileExtension: "pdf",
    };

    axios
      .post("https://tilia.rrota.org/api/Management/AddTasksMaterial", fileData)
      .then((response) => {
        if (response.status === 200) {
          // Upload was successful
          this.showAlert = true;
          this.alertColor = "success";
          this.alertText = "Material List uploaded successfully.";

          // Log a message to confirm that the response status is 200

          // Call reloadPage after a 2-second delay
          setTimeout(() => {
            this.reloadPage();
          }, 2000);
        } else {
          // Upload failed
          this.showAlert = true;
          this.alertColor = "error";
          this.alertText = "Error uploading material list.";
          setTimeout(() => {
               this.showAlert = false; 
          }, 2000);
          // Log a message to confirm that the response status is not 200
          console.log("Upload failed. Response status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        this.showAlert = true;
        this.alertColor = "error";
        this.alertText = "Error uploading material list.";
        setTimeout(() => {
        this.showAlert = false; 
          }, 2000);
        // Log the error message to the console
        console.error("Error:", error);
      });
  }
},


  },
};
</script>
<style scoped>
.material-list {
  margin-top: 220px;
  text-align: center;
  padding: 40px;
}


.empty-state-text {
  font-size: 18px;
  margin-bottom: 20px;
}

.empty-state button {
  margin-top: 20px;
}

.table-container {
  margin-top:-250px !important;
}
.download-btn {
  margin-bottom:23px;

}

.center-table {
  margin: 0 auto;
}
.green--text {
  margin-left:-30px !important;


}
.empty-state-icon {
  font-size: 64px;
  margin-left: 20px; /* Adjust the margin to move the icon to the right */
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 720px; /* Adjust as needed */
}
.upload-button {
  margin-right: 20px; /* Add space between buttons */
}

.show-pdf {
  margin-left: 20px; /* Add space between buttons */
}
.theme--dark.v-card > .v-card__text, .theme--dark.v-card .v-card__subtitle {
color: black

}
</style>